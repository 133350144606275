import React, { useRef } from "react";
import { Link } from "gatsby"
import GetCTA from "../../getcta";
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import GetStaff from "../../get-staff-strapi";
import "./About.scss"
import AppLinks from "../../app_links";
// Header component

const About = (props) => {
    const idVal = props.member?"short-intro-with-staff": (props?.id);
    return (
        <React.Fragment>          
            <section className="about-section pb-0 pt-0" id={idVal}>
                <Container>
                    <Row>
                        <Col lg={props.member?8:""}>
                            <div className="about-info">
                                
                                    <span className="small-title">{props.title?props.title:""}</span>
                                    <div className="about-content">{parse(props.content)}
                                    {props.cta && 
                                         <div className="about-cta">                                           
                                                    <>
                                                    { props.cta.CTA_Custom_Link ?
                                                     <Link to={props.cta.CTA_Custom_Link} className="btn btn-primary">{props.cta.CTA_Label}</Link>
                                                                                                     
                                                    :
                                                        <GetCTA Label={props.cta.CTA_Label} class="btn btn-primary" link={props.cta.CTA_Link && props.cta.CTA_Link.Primary_URL}/>
                                                    }
                                                    </>
                                         </div>

                                    }
                                    {props.appLinks && 
                                        <AppLinks androidlink={props.appLinks?.Playstore_Link} appstorelink={props.appLinks?.App_Store_Link}/>
                                    }
                                    </div>
                            </div>
                        </Col>
                        
                        {props.member?
                        <Col lg={4} md={6}>
                            <div className="right-block">
                            <GetStaff member={props.member} />
                            </div>
                        </Col>
                        
                        :""}

                        
                    </Row>     
                               
                </Container>
            </section>
        </React.Fragment>
    )
}
export default About
